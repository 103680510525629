import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinksItems,
    FooterLinkTitle,
    FooterLink,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    WebsiteRights,
    SocialIcons,
    SocialIconLink,
} from './FooterElements';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';


const Footer = () => { 

    const toggleHome = () => {
        scroll.scrollToTop();
    }

 
   const changeLanguage = (ln) => {
       return() => {
           i18n.changeLanguage(ln);
       }
   }
 
   const {t, i18n} = useTranslation();
   const history = useHistory();

    return (
        <FooterContainer>
           <FooterWrap>
               <FooterLinksContainer>
                   <FooterLinksWrapper>
                       <FooterLinksItems>
                           <FooterLinkTitle>{t('footer_about_us')}</FooterLinkTitle>
                               <FooterLink to="/">{t('footer_about_us-1')}</FooterLink>
                               <FooterLink to="/">{t('footer_about_us-2')}</FooterLink>
                               <FooterLink to="/">{t('footer_about_us-3')}</FooterLink>
                               <FooterLink to="/imprint" >{t('footer_about_us-4')}</FooterLink>
                               <FooterLink to="/">{t('footer_about_us-5')}</FooterLink>
                       </FooterLinksItems>
                       <FooterLinksItems>
                           <FooterLinkTitle>{t('footer_contact_us')}</FooterLinkTitle>
                               <FooterLink to="/">{t('footer_contact_us-1')}</FooterLink>
                               <FooterLink to="/">{t('footer_contact_us-2')}</FooterLink>
                               <FooterLink to="/">{t('footer_contact_us-3')}</FooterLink>
                               <FooterLink to="/">{t('footer_contact_us-4')}</FooterLink>
                       </FooterLinksItems>
                   </FooterLinksWrapper>
                   <FooterLinksWrapper>
                       <FooterLinksItems>
                           <FooterLinkTitle>{t('footer_languages')}</FooterLinkTitle>
                               <FooterLink to="/" onClick={changeLanguage('de')}>🌍 {t('footer_languages-1')}</FooterLink>
                               <FooterLink to="/" onClick={changeLanguage('en')}>🌎 Englisch</FooterLink>
                       </FooterLinksItems>
                    {/*    <FooterLinksItems>
                           <FooterLinkTitle>Social Media</FooterLinkTitle>
                               <FooterLink >Instagram</FooterLink>
                               <FooterLink >Facebook</FooterLink>
                               <FooterLink >YouTube</FooterLink>
                               <FooterLink >Twitter</FooterLink>
                    </FooterLinksItems> */}
                   </FooterLinksWrapper>
               </FooterLinksContainer>
               <SocialMedia>
                   <SocialMediaWrap>
                       <SocialLogo to="/" onClick={toggleHome}>
                          SolarCentral
                       </SocialLogo>
                       <WebsiteRights>{t('footer_copyright')}</WebsiteRights>
                       <SocialIcons>
                           <SocialIconLink href="https://www.facebook.com/profile.php?id=100075699104602" target="_blank" aria-label="Facebook">
                               <FaFacebook/>
                           </SocialIconLink>
                           <SocialIconLink href="https://www.instagram.com/solarcentral_offical/" target="_blank" aria-label="Instagram">
                               <FaInstagram/>
                           </SocialIconLink>
                           <SocialIconLink href="https://www.youtube.com/channel/UCwAF-7FnSUQqLSxQMydPzZA" target="_blank" aria-label="Youtube">
                               <FaYoutube/>
                           </SocialIconLink>
                           <SocialIconLink href="https://twitter.com/SolarCentral2" target="_blank" aria-label="Twitter">
                               <FaTwitter/>
                           </SocialIconLink>
                       </SocialIcons>
                   </SocialMediaWrap>
               </SocialMedia>
           </FooterWrap> 
        </FooterContainer>
    )
}

export default Footer
