import React, { useEffect, useState } from 'react';
import {FaBars} from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import {
Nav, 
NavbarContainer, 
NavLogo,
MobileIcon,
NavMenu,
NavItem,
NavLinks,
NavBtn,
NavBtnLink
} from './NavbarElement';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const Navbar = ({toggle}) => {
    const [scrollNav, setScrollNav] = useState(false);

/*
    const changeNav = () => {

        if(window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }
    
   

    useEffect(() =>  {
        window.addEventListener('scroll', changeNav)
    }, [])

    */

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    const toggleToTop = () => {
        scroll.scrollToTop();
        history.push('/advisory');
    }

    const {t, i18n} = useTranslation();

    const history = useHistory();



    return (
        <>
        <Nav>
            <NavbarContainer>
                <NavLogo to="/" onClick={toggleHome}>SolarCentral</NavLogo>
                <MobileIcon onClick={toggle}>
                    <FaBars/>
                </MobileIcon>
                <NavMenu>
                    <NavItem>
                        <NavLinks 
                        to="about"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        >{t('navbar-1')}</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks 
                        to="discover"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}>{t('navbar-2')}</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks 
                        to="services"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}>{t('navbar-3')}</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks 
                        onClick={() => toggleToTop()}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}>{t('navbar-4')}</NavLinks>
                    </NavItem>

                </NavMenu>
                <NavBtn>
                    <NavBtnLink to="/signin">{t('navbar-button')}</NavBtnLink>
                </NavBtn>
            </NavbarContainer>
        </Nav>
        </>
    )
}

export default Navbar;
