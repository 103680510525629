import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationDE from './locales/de/translationDE.json';
import translationEN from './locales/en/translationEN.json';

const resources = {
    de: {
       translation: translationDE
    },
    en: {
        translation: translationEN
    }
};

i18n
   .use(initReactI18next)
   .init({
       resources,
       lng: 'de',

       keySeparator: false,

       interpolation: {
           escapeValue: false
       }
   });

export default i18n;