import React from 'react';
import './grant.css';
import Footer from '../../components/Footer';
import {TiWarning} from 'react-icons/ti';

function grant() {
    return (
        <>
        <div className="grant_main">
          <h1> Wichtige Informationen</h1>
          <TiWarning className="warning_icon"/>
          <div className="grant_container">
            <p>Sehr geehrte Damen und Herren,

 <br/>
 <br/>

Wichtige Information:


<br/>
 <br/>

Dank dem Bundesamt für Wirtschaft und Ausfuhrkontrolle (BAFA) und nationalen De-minimis-Beihilfen werden aktuell staatliche Fördergelder an geeignete Betriebe vergeben, um eine Steigerung der Energieeffizienz zu realisieren.

<br/>
 <br/>
Möglich sind pro Betrieb bis zu 8.000,- € je Gebäude für die Energieberatung nach DIN V 18599 und dadurch bis zu 200.000,- € für effizienzsteigernde Maßnahmen.

<br/>
 <br/>
Die Überprüfung der Fördermöglichkeiten ist unverbindlich und kostenfrei und nimmt circa 15 Minuten in Anspruch. Bei Erfüllung aller Kriterien erhalten Sie zeitnah den BAFA-Zuwendungsbescheid und werden bei jedem weiteren Schritt begleitet.


<br/>
 <br/>

Bitte leiten Sie diese Mail an die verantwortliche Person im Betrieb weiter, falls Sie nicht der richtige Ansprechpartner sind.

<br/>
 <br/>

Laut internen Analysen haben Betriebe in der Vergangenheit jährlich Fördergelder in Höhe von jeweils 100.000,- € verpasst

<br/>
- das sollten Sie vermeiden!

<br/>
 <br/>

Bitte beachten Sie: Noch ist der Fördertopf gefüllt und Sie können sich diese Zuschüsse sichern, indem Sie rechtzeitig einen Termin zur Überprüfung vereinbaren!
</p>
          </div>
        </div>
        <Footer/>
        </>
    )
}

export default grant
