import styled from "styled-components";
import {FaTimes} from 'react-icons/fa';
import {Link as LinkS} from 'react-scroll';
import {Link as LinkR} from 'react-router-dom';

export const SidebarContainer = styled.aside`
 position: fixed;
 z-index: 999;
 width: 100%;
 height: 100%;
 background: #f7f6f7;
 display: grid;
 align-items: center;
 top: 0;
 left: 0;
 transition: 0.3s ease-in-out;
 opacity: ${({ isOpen }) => (isOpen ? '100%' : '0%')};
 top: ${({ isOpen }) => (isOpen ? '0'  : '-100%')};
 
`
export const CloseIcon = styled(FaTimes)`
 color: #030b15;
`

export const Icon = styled.div`
 position: absolute;
 top: 1.2rem;
 right: 1.5rem;
 background: transparent;
 font-size: 2rem;
 cursor: pointer;
 outline: none;
`

export const SidebarWrapper = styled.div`
 color: #030b15;
`

export const SidebarMenu = styled.ul`
 display: grid;
 grid-template-columns: 1fr;
 grid-template-rows: repeat(6, 80px);
 text-align: center;
 margin: 20px;

 @media screen and (max-width: 480px) {
    grid-template-rows: repeat(6, 60px);
 }
`

export const SidebarLink = styled(LinkS)`
 display: flex;
 align-items: center;
 justify-content: center;
 font-size: 1.5rem;
 text-decoration: none;
 list-style: none;
 transition: 0.2s ease-in-out;
 text-decoration: none;
 color: #030b15;    
 cursor: pointer;
 align-self: center;
 margin-top: 20px;
 border: 1px solid #030b15;
 border-radius: 5px;

 &:hover {
     color: #192d50;
     transition: 0.2s ease-in-out;
 }
`

export const SideBtnWrap = styled.div`
 display: flex;
 justify-content: center;
`

export const SidebarRoute = styled(LinkR)`
 border-radius: 50px;
 background: #192d50;
 white-space: nowrap;
 padding: 16px 64px;
 color: #f7f6f7;
 font-size: 16px;
 outline: none;
 border: none;
 cursor: pointer;
 transition: all 0.2s ease-in-out;
 text-decoration: none;

 &:hover {
     transition: all 0.2s ease-in-out;
     background: #030b15;
     color: #f7f6f7;
 }
`
