import React, {useRef, useState} from 'react';
import { FaLock, FaEnvelope, FaLockOpen, FaUser } from 'react-icons/fa';
import './Register.css';
import {NavLink, useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import SyncLoader from "react-spinners/SyncLoader";
import { useTranslation } from 'react-i18next';

function Register() {
 
    const [show, setShow] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordCon, setPasswordCon] = useState('');
 
    const [loading, setLoading] = useState(false);
    const color = useState('#030b15');

    const emailRef    = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const {signup}    = useAuth();
    const [error, setError] = useState('');

    const history = useHistory();

    async function handleLogin(e){
      e.preventDefault();
 
      if(passwordRef.current.value !== passwordConfirmRef.current.value){
        return setError(t('auth_register_password_same'));
      }

      try{
        setLoading(true);
        setError('');
        setEmail('');
        setPassword('');
        setPasswordCon('');
        await signup(emailRef.current.value, passwordRef.current.value);
        history.push('/signin')
      }catch{
        setError(t('auth_register_error'));
      }
      setLoading(false);
    }
  
    const {t, i18n} = useTranslation();

    return (
      <div className="main_register">
         <NavLink to="/">
           <h1>SolarCentral</h1>
          </NavLink>
          { error && 
           <div className="alert_container">          
             <div className="alert">
               <span className="msg">{error}</span>
              </div>
            </div>  
          }
           <div className="login_body">
                <div className="login_register-div">
                   <div className="login_logo"></div>
                   <div className="login_title">
                     {t('auth_register')}
                   </div>
                   <div className="fields">
                     <div className="email_input">
                        <div className="icon_input">
                         <FaUser  style={{marginTop: 4}}/>
                        </div> 
                         <input 
                         type="text" 
                         className="email-input"
                         placeholder={t('auth_fullname')}
                         required/>
                       </div>
                       <div className="email_input">
                        <div className="icon_input">
                         <FaEnvelope  style={{marginTop: 4}}/>
                        </div> 
                         <input 
                         type="email" 
                         className="email-input"
                         placeholder="Email"
                         ref={emailRef}
                         value={email}
                         onChange={() => setEmail()}
                         required/>
                       </div>
                       <div className="password_input">
                          <div 
                          className="icon_input"
                          onClick={() => setShow(!show)}>
                            {show ?
                              <FaLockOpen  style={{marginTop: 4}} /> :
                              <FaLock  style={{marginTop: 4}}/>
                            } 
                          </div> 
                         <input
                         type={show ? "text" : "password"}
                         className="password-input"
                         placeholder={t('auth_password_input')}
                         ref={passwordRef}
                         value={password}
                         onChange={() => setPassword()}
                         required/>
                       </div>
                       <div className="password_input">
                          <div 
                          className="icon_input"
                          onClick={() => setShow(!show)}>
                            {show ?
                              <FaLockOpen  style={{marginTop: 4}}/> :
                              <FaLock  style={{marginTop: 4}}/>
                            } 
                          </div> 
                         <input
                         type={show ? "text" : "password"}
                         className="password-input"
                         placeholder={t('auth_password_confirm')}
                         ref={passwordConfirmRef}
                         value={passwordCon}
                         onChange={() => setPasswordCon()}
                         required/>
                       </div>
                   </div>
                   <button className="login-button" onClick={handleLogin} >
                       {t('auth_register')}
                   </button>
                   <div className="login-link">
                       <a href="/signin">{t('auth_login')}</a>
                   </div>
                   <SyncLoader color={color} size={10} loading={loading}/>
                </div>
            </div>
        </div>
    )
}

export default Register
