import React from 'react';
import './fourzerofour.css';

function fourzerofour() {
    return (
        <div className="fourzerofour_main">
            <div className="error_page">
               <div className="error_content">
                  <h1>404</h1>
                  <h4>Opps! Page not found</h4>
                  <p>Sorry, the page you're looking for dosen't exist. If you think something is broken, report a problem.</p>
                 <div className="error_btns">
                    <a href="/">return home</a>
                   {/* <a href="#">report problem</a> */}
                 </div>
               </div>
            </div>
        </div>
    )
}

export default fourzerofour
