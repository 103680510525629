import React, { useState } from 'react';
import Footer from '../../components/Footer';
import './Advisory.css';
import { useHistory } from 'react-router';
import imgseven from '../../images/img-7.jpeg';
import { render } from 'react-dom';
import { AiOutlineArrowRight, AiOutlineArrowLeft, AiOutlineCheckCircle} from 'react-icons/ai';
import {BsCheck2Circle, BsFillBagCheckFill} from 'react-icons/bs';
import {ImPriceTags} from 'react-icons/im';
import {GiGreenPower} from 'react-icons/gi';
import {MdReduceCapacity} from 'react-icons/md';
import validator from 'validator';
import emailjs from "emailjs-com";
import SyncLoader from "react-spinners/SyncLoader";



function Advisory() {
 
    const history = useHistory();
 
    const [page, setPage] = useState(1);
    const [disabledBtnBack, setDisabledBtnBack] = useState(false);
    const [disabledBtnNext, setDisabledBtnNext] = useState(false);


    function goNextPage(){
       
        if (page < 5){
         setPage((page) => page + 1)
        }
    }

    function goBackPage(){
        if(page > 1){
         setPage((page) => page - 1)
        }
    }

    return (
       <> 
        <div className="advisory_main">
            <h1 onClick={() => history.push('/')} style={{color: 'whitesmoke'}}>SolarCentral</h1>
            <div className="advisory_container"> 
              <div className="advisory_content">
                <h2>5-minuten Online Solar beratung</h2>
                <p style={{color: 'whitesmoke'}}>Vergleichen Sie jetzt kostenlos und unverbindlich alle Anbieter. Mit unserem Solar-rechner finden Sie spielend das passende Angebot für Ihr Photovoltaik-Projekt.</p>
                <div className="ad_card">
                   {page === 1 && <OnboardingOne/>}
                   {page === 2 && <OnboardingTwo/>}
                   {page === 3 && <OnboardingThree/>}
                   {page === 4 && <OnboardingFour/>}
                   {page === 5 && <OnboardingFive/>}
                   <div className="ad_card_content_btn">
                    <button disabled={disabledBtnBack} onClick={() => goBackPage()}><AiOutlineArrowLeft className="btn_icon"/></button>
                    <button disabled={disabledBtnNext}  onClick={() => goNextPage()} style={{marginLeft: 10}}><AiOutlineArrowRight className="btn_icon"/></button>
                    </div>      
                </div>
              </div>
            </div>
            <div className="advisory_content_section_one">
                <img src={imgseven}></img>
                <h2>Alle Anbieter vergleichen und bares Geld sparen.</h2>
                <p>Durch den Verbau einer Photovoltaikanlage können Sie Stromkosten einsparen oder sogar komplett autark werden.</p>
                <ul>
                  <li><BsCheck2Circle className="check_icon"/><p>Für Privat und Gewerbe geeignet.</p></li>
                  <li><BsCheck2Circle className="check_icon"/><p>Mit uns finden Sie das beste Angebot.</p></li>
                  <li><BsCheck2Circle className="check_icon"/><p>Sonderaktion: Solaranlage ohne monatliche Mehrkosten möglich.</p></li>
                </ul>
             </div>
             <div className="advisory_content_section_twos">
             <div className="advisory_content_section_two">
                <h2>Unser Service</h2>
                <p>Tagesaktuell, kostenlos und unverbindlich finden wir für Sie das beste Angebot für Ihre Solaranlage. Unser Expertenwissen aus über 10 Jahren in der Branche geben wir hier an Sie weiter damit Sie schneller an Ihr Ziel kommen: Kosten einsparen.</p>
                <div className="advisory_content_section_container">
                  <div className="advisory_content_section_card">
                    <div className="advisory_content_section_imgBx">
                       <ImPriceTags style={{color: '#FF7779'}} className="advisory_content_section_icon"/>
                     </div>
                     <div className="advisory_content_section_contentBx">
                        <div className="advisory_content_section_content">
                          <h3>Energiekosten senken</h3>
                          <p>
                          Sie produzieren und verbrauchen Ihren eigenen Strom und senken so Ihre monatlichen Kosten. Wartungsfrei und jahrzehntelang.
                          </p>
                        </div>
                     </div>
                  </div>
                  <div className="advisory_content_section_card">
                    <div className="advisory_content_section_imgBx">
                       <GiGreenPower style={{color: '#D1F3C5'}} className="advisory_content_section_icon"/>
                     </div>
                     <div className="advisory_content_section_contentBx">
                        <div className="advisory_content_section_content">
                          <h3>Umweltbewusst handeln</h3>
                          <p>
                          Sie tragen zur Energiewende bei indem Sie Ihren Strom durch die Sonne produzieren lassen. Völlig geräusch- und emissionsarm.
                          </p>
                        </div>
                     </div>
                  </div>
                  <div className="advisory_content_section_card">
                    <div className="advisory_content_section_imgBx">
                       <BsFillBagCheckFill style={{color: '#bad3e9'}} className="advisory_content_section_icon"/>
                     </div>
                     <div className="advisory_content_section_contentBx">
                        <div className="advisory_content_section_content">
                          <h3>Strom einfach mitnehmen</h3>
                          <p>
                          Wussten Sie, dass Sie Ihren produzierten Strom auch "mitnehmen" können? Nutzen Sie Ihren Strom z.B. auf der Autobahn um Ihr E-Auto zu laden.
                          </p>
                        </div>
                     </div>
                  </div>
                  <div className="advisory_content_section_card">
                    <div className="advisory_content_section_imgBx">
                       <MdReduceCapacity style={{color: '#aaaeb2'}} className="advisory_content_section_icon"/>
                     </div>
                     <div className="advisory_content_section_contentBx">
                        <div className="advisory_content_section_content">
                          <h3>Strom zusammen nutzen</h3>
                          <p>
                          Ihre Angehörigen wohnen nicht bei Ihnen sollen aber trotzdem Ihren Solarstrom nutzen? Kein Problem: Andere Haushalte können Ihren Strom erhalten.
                          </p>
                        </div>
                     </div>
                  </div>
                </div>
             </div>
             </div>
        </div>
        <Footer/>
       </> 
    )
}

export default Advisory


function OnboardingOne() {



    return <div className="ad_card_status">
    <h1><span>1</span> von 4 Schritten</h1>
    <div className="ad_card_content">
     <h3>Möchten Sie Ihre Solaranlage mieten oder kaufen?</h3>
     <div className="ad_radio">
     <input id="confirm" name="buy" type="checkbox" style={{width: 15, height:15}} required></input><label style={{fontSize:18}} for="confirm">Anlage kaufen</label>
     <br/>
     <input id="confirm" name="month" type="checkbox" style={{width: 15, height:15}} required></input><label style={{fontSize:18}} for="confirm">Anlage ab 54,00 Euro pro Monat mieten</label>
     <br/>
     </div>
    </div>
    <div className="ad_card_content">
     
     <h5>Worfür ist Ihre Anlage?</h5>
     <div className="ad_radio">
     <input id="confirm" name="onefamily" type="checkbox" style={{width: 15, height:15}}></input><label style={{fontSize:18}} for="confirm">Für ein Einfamilienhaus</label>
     <br/>
     <input id="confirm" name="morefamily" type="checkbox" style={{width: 15, height:15}}></input><label style={{fontSize:18}} for="confirm">Für ein Mehrfamilienhaus</label>
     <br/>
     <input id="confirm" name="company" type="checkbox" style={{width: 15, height:15}}></input><label style={{fontSize:18}} for="confirm">Für ein Firmengebäude</label>
     <br/>
    
     </div>
    </div>
 
  </div>;
}


function OnboardingTwo() {
    return <div className="ad_card_status">
    <h1><span>2</span> von 4 Schritten</h1>
   <div className="ad_card_content">
     <h3>Um was für eine Dachfläche handelt es sich?</h3>
     <h5>Welche Ausrichtung hat Ihr Dach?</h5>
     <div className="ad_radio">

     <input id="confirm" name="south" type="checkbox" style={{width: 15, height:15}}></input><label style={{fontSize:18}} for="confirm">Süd</label>
     <br/>
     <input id="confirm" name="south-west" type="checkbox" style={{width: 15, height:15}}></input><label style={{fontSize:18}} for="confirm">Ost</label>
     <br/>
     <input id="confirm" name="west" type="checkbox" style={{width: 15, height:15}}></input><label style={{fontSize:18}} for="confirm">West</label>
     <br/>
     <input id="confirm" name="west" type="checkbox" style={{width: 15, height:15}}></input><label style={{fontSize:18}} for="confirm">Ost-West</label>
     <br/>
     <input id="confirm" name="else" type="checkbox" style={{width: 15, height:15}}></input><label style={{fontSize:18}} for="confirm">Sonstiges</label>
     <br/>

     </div>

    </div>
    <div className="ad_card_content">
     <h5>Welche Neigung hat Ihr Dach circa?</h5>
     <div className="ad_radio">
     <input id="confirm" name="flat" type="checkbox" style={{width: 15, height:15}}></input><label style={{fontSize:18}} for="confirm">0° bis 17° flache Neigung</label>
     <br/>
     <input id="confirm" name="middle" type="checkbox" style={{width: 15, height:15}}></input><label style={{fontSize:18}} for="confirm">18° bis 33° mittlere Neigung</label>
     <br/>
     <input id="confirm" name="steep" type="checkbox" style={{width: 15, height:15}}></input><label style={{fontSize:18}} for="confirm">34° bis 44° steile Neigung</label>
     <br/>
     </div>

    </div>
 
  </div>;
}


function  OnboardingThree() {
    return<div className="ad_card_status">
    <h1><span>3</span> von 4 Schritten</h1>
   <div className="ad_card_content">
     <h3>Weitere Details rund um Ihre Dachfläche</h3>
     <h5>Welche Größe hat Ihre Dachfläche?</h5>
     <div className="ad_radio">

     <input id="confirm" name="lowest" type="checkbox" style={{width: 15, height:15}}></input><label style={{fontSize:18}} for="confirm">Bis 40m²</label>
     <br/>
     <input id="confirm" name="low" type="checkbox" style={{width: 15, height:15}}></input><label style={{fontSize:18}} for="confirm">40m² - 110m²</label>
     <br/>
     <input id="confirm" name="middlehigh" type="checkbox" style={{width: 15, height:15}}></input><label style={{fontSize:18}} for="confirm">110m² - 220m²</label>
     <br/>
     <input id="confirm" name="high" type="checkbox" style={{width: 15, height:15}}></input><label style={{fontSize:18}} for="confirm">Über 220m²</label>
     <br/>

     </div>

    </div>

  </div>;
}


function  OnboardingFour() {
    return <div className="ad_card_status">
    <h1><span>4</span> von 4 Schritten</h1>
   <div className="ad_card_content">
     <h3>Wichtige Fragen rund um Ihren derzeitigen Stromverbrauch</h3>
     <h5>Ihr derzeitiger Stromverbrauch?</h5>
     <div className="ad_radio">

     <input id="confirm" name="low_energy" type="checkbox" style={{width: 15, height:15}}></input><label style={{fontSize:18}} for="confirm">1.000 bis 10.000 kWh</label>
     <br/>
     <input id="confirm" name="high_energy" type="checkbox" style={{width: 15, height:15}}></input><label style={{fontSize:18}} for="confirm">Über 10.000 kWh</label>
     <br/>
     </div>

    </div>

    </div>;
}

function  OnboardingFive() {
     
        const [emailError, setEmailError] = useState('')

        const validateEmail = (e) => {
            var email = e.target.value
    
            if(validator.isEmail(email)){
                setEmailError('');
            } else {
                setEmailError('Email Ungültig')
            }
        }


        const [surname, setSurname]         = useState('');
const [lastname, setLastname]       = useState('');
const [phonenumber, setPhoneNumber] = useState('');
const [email, setEmail]             = useState(''); 

const [loading, setLoading] = useState(false);
const [show, setShow]       = useState(false);
const color = useState('#030b15');


function sendEmail(e) {
  e.preventDefault();
  setLoading(true);
emailjs.sendForm(
  'service_bqm7lkm',
  'template_bpnhr3k',
  e.target,
  'user_1jar6imF0eXW7r9XDjrsx'
).then(res => {
  setLoading(false);
  setShow(true);
 setSurname('');
 setLastname('');
 setEmail('');
 setPhoneNumber('');
}).catch( err => {
  console.log(err);
});
}



    
    return <div className="ad_card_status">
    <h1>Ihr Ergebnis</h1>
   <div className="ad_card_content">
    <h3>Ihr Ergebnis:</h3>
     <p style={{marginTop:10, marginBottom: 10}}>Ab <span style={{fontWeight: 'bold'}}>54 €</span> mtl. könnten Sie eine Anlage mieten, auch ohne monatliche Mehrkosten wäre möglich. Beim Kauf können Sie <span style={{fontWeight: 'bold'}}>35 %</span> sparen.</p>
     <h3>Fordern Sie Ihren kostenlosen und unverbindlichen Vergleich an:</h3>
     <div className="ad_radio">
      <form onSubmit={sendEmail}>
     <div className="fields_form">
       
                       <div className="surname_input">
                        <label>Vorname</label>
                         <input 
                         value={surname} 
                         onChange={() => setSurname()}
                         name="surname"
                         type="text" 
                         placeholder="Max"
                         required/>
                       </div>
                       <div className="lastname_input">
                       <label>Nachname</label>
                         <input
                          value={lastname} 
                          onChange={() => setLastname()}
                         type= "text"
                         name="lastname"
                         placeholder="Mustermann"  
                         required/>
                       </div>
                       <div className="email_input_form">
                       <label>Email</label>
                       <span className="email_error">{emailError}</span>
                         <input
                         name="email"
                         onChange={(e) => validateEmail(e)}
                         type= "email"
                         placeholder="max.mustermann@gmail.com"  
                         required/>
      
                       </div>
                       <div className="lastname_input">
                       <label>Telefon-Nummer für Rückfragen</label>
                         <input
                           value={phonenumber} 
                          onChange={() => setPhoneNumber()}
                         name="telefon"
                         type= "text"
                         placeholder="+49 123 456789"  
                         required/>
                       </div>
           
                   </div>
            
                   <button type="submit" className="send">
                    Jetzt Anforden
                   </button>     
                </form> 
        </div>
        <SyncLoader color={color} size={8} loading={loading}/>
          {
            show ? 
          <div className="success_advisory">
           <AiOutlineCheckCircle style={{marginTop: 10, fontSize: 20, color: 'green'}}/> 
           <p>Danke für die Teilnahme wir melden uns bei Ihnen !</p>
          </div>
          : null
          }
     </div>

     
    </div>;}