import { useTranslation } from 'react-i18next';


export const homeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Klima',
    headline: 'Wir kämpfen für das Klima 🌍',
    description: 'SolarCentral treibt die Energierevolution voran. Wir sind hier, weil wir die Fähigkeit, die Verantwortung und die Leidenschaft haben, diese Welt zu einem besseren Ort zu machen.',
    buttonLabel: 'Mach mit',
    imgStart: false,
    img: require('../../images/img-1.jpeg').default,
    alt: 'Sun',
    dark: false,
    primary: true,
    darkText: true,
    redirect: '/advisory',
    
}

export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'Kunden',
    headline: 'Wir lieben unsere Kunden 💕',
    description: 'Unsere Kunden sind der Herzschlag unseres Unternehmens. Wir arbeiten unermüdlich, um das Vertrauen unserer Kunden zu gewinnen und zu erhalten. Ohne unsere Kunden können wir nicht gewinnen.',
    buttonLabel: 'Mehr erleben',
    imgStart: true,
    img: require('../../images/img-2.jpeg').default,
    alt: 'Walking',
    dark: false,
    primary: true,
    darkText: true,
    redirect: '/advisory'
}

export const homeObjThree = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,  
    topLine: '',
    headline: 'So funktionierts',
    description: '✔Angaben zum Haus  ✔Anlage wird konfiguriert  ✔Persönliche Beratung  ✔Professionelle Montage',
    buttonLabel: 'Starte Jetzt',
    imgStart: false,
    img: require('../../images/img-3.jpeg').default,
    alt: 'Enviroment',
    dark: false,
    primary: true,
    darkText: true,
    redirect: '/advisory'
}