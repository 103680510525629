import React, { useRef, useState } from 'react';
import { FaLock, FaEnvelope, FaLockOpen } from 'react-icons/fa';
import { NavLink, useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import './login.css';
import SyncLoader from "react-spinners/SyncLoader";
import { useTranslation } from 'react-i18next';


function Login() {

   const [show, setShow]         = useState(false);
   const [email, setEmail]       = useState('');
   const [password, setPassword] = useState('');

   const emailRef        = useRef();
   const passwordRef     = useRef();
   const {login}         = useAuth();
   const history = useHistory();
 
   const [loading, setLoading] = useState(false);
   const color = useState('#030b15');

   const [error, setError] = useState('');

   async function handleLogin(e){
    
    try{
      setLoading(true);
      setError('');
      setEmail('');
      setPassword('');
      await login(emailRef.current.value, passwordRef.current.value);
      history.push('/user-dashboard');
    } catch{
      setError(t('auth_login_error'))
    }
    setLoading(false);
   }
 
   const {t, i18n} = useTranslation();
    
    return (
      <div>
        <div className="main_login">
          <NavLink to="/">
           <h1>SolarCentral</h1>
          </NavLink>
          { error && 
           <div className="alert_container">          
             <div className="alert">
               <span className="msg">{error}</span>
              </div>
            </div>  
          }
           <div className="login_body">
                <div className="login-div">
                   <div className="login_logo"></div>
                   <div className="login_title">
                     {t('auth_login')}
                   </div>
                   <div className="fields">
                       <div className="email_input">
                        <div className="icon_input">
                         <FaEnvelope  style={{marginTop: 4}}/>
                        </div> 
                         <input 
                         type="email" 
                         className="email-input"
                         placeholder="Email"
                         ref={emailRef}
                         value={email}
                         onChange={() => setEmail()}
                         required/>
                       </div>
                       <div className="password_input">
                          <div 
                          className="icon_input"
                          onClick={() => setShow(!show)}>
                            {show ?
                              <FaLockOpen style={{marginTop: 4}}/> :
                              <FaLock  style={{marginTop: 4}}/>
                            } 
                          </div> 
                         <input
                         type={show ? "text" : "password"}
                         className="password-input"
                         placeholder={t('auth_password_input')}  
                         ref={passwordRef}                        
                         value={password}
                         onChange={() => setPassword()}
                         required/>
                       </div>
                   </div>
                   <button className="login-button" onClick={() => handleLogin()}>
                   {t('auth_login')}
                   </button>
                   <div className="login-link">
                      <a href="/forgot-password">{t('auth_forgot-password')}</a> {t('auth_or')} <a href="/register">{t('auth_register')}</a>
                   </div>
                   <SyncLoader color={color} size={10} loading={loading}/>
                </div>
            </div>
        </div>
      </div> 
    )
}

export default Login
