import React, { useState } from 'react';
import { Button } from '../ButtonElement';
import Video from '../../videos/video.mp4';
import { useTranslation } from 'react-i18next';
import {
 HeroContainer,
 HeroBg,
 VideoBg,
 HeroContent,
 HeroH1,
 HeroP,
 HeroBtnWrapper,
 ArrowForward,
 ArrowRight
} from './HeroElements';

const HeroSection = () => {
 
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover)
    }

    const {t, i18n} = useTranslation();

    return (
        <>
        <HeroContainer>
            <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type="video/mp4"/>

            </HeroBg>
            <HeroContent>
                <HeroH1>{t('headlineHero')}</HeroH1>
                <HeroP>{t('descriptionHero')}</HeroP>
                <HeroBtnWrapper>
                    <Button 
                    to="signup" 
                    onMouseEnter={onHover} 
                    onMouseLeave={onHover}
                    primary='true'
                    dark='true'>
                      {t('buttonHero')} {hover ? <ArrowForward/> : <ArrowRight/>}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
            <div className="home_text_garantie">
                <h2>20 Jahre Garantieleistung</h2>
              </div>
        </HeroContainer>

        </>
    )
}

export default HeroSection
