import React from 'react'
import { useState } from 'react'
import Footer from '../components/Footer'
import HeroSection from '../components/HeroSection'
import InfoSection from '../components/InfoSection'
import { homeObjOne, homeObjTwo,  homeObjThree} from '../components/InfoSection/Data'
import Navbar from '../components/Navbar'
import Services from '../components/Services'
import Sidebar from '../components/Sidebar'
import './index.css'

import { useTranslation } from 'react-i18next';
import CardOne from '../images/card_img-1.jpeg';

import { useHistory } from 'react-router';
import { HiArrowDown } from 'react-icons/hi';
import { animateScroll as scroll } from 'react-scroll';

const Home = () => {

     
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  
  const scrollToTop = () => {
    scroll.scrollToTop();
    history.push('/advisory');
  }

  const scrollToTopToState = () => {
    scroll.scrollToTop();
    history.push('/grant')
  }

  const {t, i18n} = useTranslation();
 
  const history = useHistory();

    return (
        <>
          <Sidebar isOpen={isOpen} toggle={toggle}/>
          <Navbar toggle={toggle}/>

          <HeroSection/>
         
          <div className="services_btn_container">
            <div className="services_btn_grant">
              <button onClick={() => scrollToTopToState()}>Staatliche Föderung</button>
            </div>
            <div className="services_btn_dropdown">
              <div className="services_btn_dropdown_select">
                 <span className="services_btn_select">4 Gründe zum Kauf</span>
                 <HiArrowDown className="services_btn_dropdown_icon"/>
              </div>
              <div className="services_btn_dropdown_list">
                <div className="services_btn_dropdown_list_item">
                  <a href="https://de.statista.com/statistik/daten/studie/1045/umfrage/inflationsrate-in-deutschland-veraenderung-des-verbraucherpreisindexes-zum-vorjahresmonat/"><span>1.</span> Inflation</a>
                </div>
                <div className="services_btn_dropdown_list_item">
                   <a href="https://www.focus.de/finanzen/boerse/konjunktur/nicht-nur-privathaushalte-aechzen-hohe-energiepreise-bedrohen-nun-den-wirtschaftlichen-aufschwung_id_24261907.html "><span>2.</span> Hohe Strompreise</a>
                </div>
                <div className="services_btn_dropdown_list_item">
                 <a href="https://www.merkur.de/wirtschaft/grossbritannien-blackout-strom-netz-e-autos-ueberlastung-elektroautos-ladestationen-kappen-zr-91018501.html"><span>3.</span> Strom Blackout</a>
                </div>
                <div className="services_btn_dropdown_list_item">
                <a href=" https://www.spiegel.de/wirtschaft/service/smiling-green-energy-und-die-energiepreise-weiterer-stromanbieter-rutscht-in-die-pleite-a-d57be1eb-026a-4418-b9ad-a12da563aed9"><span>4.</span> Stromanbieter Insolvenzen</a>
                </div>
              </div>
            </div>
           </div> 

          {/*  
          <div className="banner_main"> 
             <h3>{t('banner_popular')}</h3>
             <div className="banner_compaines">
                <img src={Bild2}></img>
                <img src={Bild4}></img>
                <img src={Bild5}></img>
                <img src={Bild6}></img>
                <img src={Bild7}></img>

             </div>
          </div>
          */}
          <InfoSection {...homeObjOne}/>
          <InfoSection {...homeObjTwo}/>
          <Services/>
         {/*   <div className="news_main">
            <div className="news_box">
                <div className="news_notification">
                  <a href="https://www.spiegel.de/thema/solarenergie/">{t('news_articel')}</a>
                  <BsNewspaper className="news_icon"/>
                  <span className="num"></span>
                </div>
            </div>
          </div>*/}
          <InfoSection {...homeObjThree}/>
           <div className="card_main">
            <div className="cards"> 
             <div className="card card1">
               <div className="card_container">
                {/* <MdRoofing className="icons_btn"/>*/}
                 <img src={CardOne}></img>
               </div>
               <div className="card_details">
                  <button onClick={() => scrollToTop()}>Solar für Dächer</button>
                  <button onClick={() => scrollToTop()}>Solar für CarPorts</button>
                  <button onClick={() => scrollToTop()}>Solar für den Balkon</button>
               </div>
             </div>
             
             </div>
           </div>


          <Footer/>
        </>
    )
}

export default Home