import './App.css';
import { BrowserRouter as Router,
         Switch,
         Route} from "react-router-dom";
import Home from './pages';
import Login from './pages/Login/login';
import Register from './pages/Register/Register';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import { AuthProvider } from './contexts/AuthContext';
import User from './pages/user/user';
import PrivateRoute from './PrivateRoute';
import FourZeroFour from './pages/404/fourzerofour';
import Grant from './pages/Grant/grant';
import Imprint from './pages/Imprint/Imprint';
import Advisory from './pages/Advisory/Advisory';

function App() {

  return (

   <AuthProvider>

    <Router>
      <Switch>
      <PrivateRoute exact path="/user-dashboard">
          <User/>
        </PrivateRoute>
        <Route path="/" component={Home} exact/>
        <Route path="/signin" component={Login} exact/>
        <Route path="/register">
          <Register/>
        </Route>
        <Route path="/forgot-password">
           <ForgotPassword/>
        </Route>
        <Route path="/grant">
          <Grant/>
        </Route>
        <Route path="/imprint">
         <Imprint/>
        </Route>
        <Route path="/advisory">
         <Advisory/>
        </Route>
        <Route path='*'>
           <FourZeroFour/>
        </Route>
      </Switch>
    </Router>
    </AuthProvider> 
    
  );
}

export default App;
