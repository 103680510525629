import firebase from 'firebase';
import 'firebase/auth';

const app = firebase.initializeApp( {
        apiKey: "AIzaSyCgpmjegJw-cwGHkA94uD6eVT85TYOSBe4",
        authDomain: "solarcentral24.firebaseapp.com",
        projectId: "solarcentral24",
        storageBucket: "solarcentral24.appspot.com",
        messagingSenderId: "755661169482",
        appId: "1:755661169482:web:db18f05f0ac118113917b3",
        measurementId: "G-FGN5Y3X2FN"
});

export const auth = app.auth();
export default app


