import React , { useRef, useState } from 'react';
import {  FaEnvelope } from 'react-icons/fa';
import {NavLink, useHistory } from 'react-router-dom';
import './ForgotPassword.css';
import { useAuth } from '../../contexts/AuthContext';
import SyncLoader from "react-spinners/SyncLoader";
import { useTranslation } from 'react-i18next';

function ForgotPassword() {
 
    const emailRef = useRef();
    const [email, setEmail] = useState('');
    const {resetPassword} = useAuth();
 
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
     
    
   const [loading, setLoading] = useState(false);
   const color = useState('#030b15');

    async function handleResetPassword(e){
        e.preventDefault();

        try{
            setLoading(true);
            setError('');
            setEmail('');
           await resetPassword(emailRef.current.value);
           setMessage(t('auth_forgot_password_message'));
        }catch{
            setError(t('auth_forgot_password_error'));
        }
        setLoading(false);
    }

    const {t, i18n} = useTranslation();

    return (
        <div className="main_forgot_password">
        <NavLink to="/">
           <h1>SolarCentral</h1>
          </NavLink>
          {error &&
           <div className="alert_container">          
                <div className="alert">
                     <span className="msg">{error}</span>
                </div>
            </div> 
          }
          {message &&
            <div className="alert_container">          
                <div className="alert_message">
                    <span className="msg">{message}</span>
                </div>
            </div> 
          }
           <div className="login_body">
                <div className="forgot_password-div">
                   <div className="login_logo"></div>
                   <div className="login_title">
                      {t('auth_forgot-password')}
                   </div>
                   <div className="fields">
                       <div className="email_input">
                        <div className="icon_input">
                         <FaEnvelope  style={{marginTop: 4}}/>
                        </div> 
                         <input 
                         type="email" 
                         className="email-input"
                         placeholder="Email"
                         ref={emailRef}
                         value={email}
                         onChange={() => setEmail()}
                         required/>
                       </div>
                   </div>
                   <button className="login-button" onClick={handleResetPassword}>
                       {t('auth_send')}
                   </button>
                   <div className="login-link">
                      <a href="/signin">{t('auth_login')}</a> {t('auth_or')} <a href="/register">{t('auth_register')}</a>
                   </div>
                   <SyncLoader color={color} size={10} loading={loading}/>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
