import React from 'react';

import {
    ServicesContainer,
    ServicesH1,
    ServicesWrapper,
    ServicesCard,
    ServicesIcon,
    ServicesH2,
    ServicesP
} from './ServicesElement';
import { FaSolarPanel } from 'react-icons/fa';
import { GiGreenPower, GiReceiveMoney } from 'react-icons/gi';
import { HiArrowDown } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import './index.css';


const Services = () => {
  
  const scrollToTop = () => {
     scroll.scrollToTop();
     history.push('/grant')
  }
   
  const {t, i18n} = useTranslation();
  const history   = useHistory();

    return (
        <ServicesContainer id="services">
            <ServicesH1>{t('advantages_headline')}</ServicesH1>
            <ServicesWrapper>
              <ServicesCard>  
                <FaSolarPanel style={{width: 70, height:70, marginBottom: 10, color: '#2B3B92'}}/>
                <ServicesH2>{t('advantages_subtitle-1')}</ServicesH2>
                <ServicesP>{t('advantages_description-1')}</ServicesP>
              </ServicesCard>   
              <ServicesCard >  
                <GiGreenPower style={{width: 70, height:70, marginBottom: 10, color: '#33b180'}}/>
                <ServicesH2>{t('advantages_subtitle-2')}</ServicesH2>
                <ServicesP>{t('advantages_description-2')}</ServicesP>
              </ServicesCard>   
              <ServicesCard>  
                <GiReceiveMoney style={{width: 70, height:70, marginBottom: 10, color: '#F1CF3B'}}/>
                <ServicesH2>{t('advantages_subtitle-3')}</ServicesH2>
                <ServicesP>{t('advantages_description-3')}</ServicesP>
              </ServicesCard>   
            </ServicesWrapper>
         
        </ServicesContainer>
    )
}

export default Services
