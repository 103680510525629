import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory } from 'react-router';
import './user.css'

function User() {
 
    const history = useHistory()
    const {currentUser, logout} = useAuth()

   async function handleLogout() {
      try{
          await logout()
          history.push('/signin');
      }catch{

      }
    }

    return (
        <div className="user_main">
            {currentUser.email}
            <button onClick={handleLogout}>Logout</button>
        </div>
    )
}

export default User
