import React from 'react';
import { 
SidebarContainer,
Icon,
CloseIcon,
SidebarWrapper,
SidebarMenu,
SidebarLink,
SideBtnWrap,
SidebarRoute
 } from './SidebarElements';
 import { useTranslation } from 'react-i18next';
 import { useHistory } from 'react-router';
 import { animateScroll as scroll } from 'react-scroll';

const Sidebar = ({isOpen, toggle}) => {

    const {t, i18n} = useTranslation();
    const history = useHistory();
 
    const toggleToTop = () => {
      scroll.scrollToTop();
      history.push('/advisory');
    }

     
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                <SidebarLink to="about" onClick={toggle}>
                    {t('navbar-1')}
                </SidebarLink>
                <SidebarLink  to="discover" onClick={toggle}>
                  {t('navbar-2')}
                </SidebarLink>
                <SidebarLink  to="services" onClick={toggle}>
                   {t('navbar-3')}
                </SidebarLink>
                <SidebarLink  to="signup" onClick={() => toggleToTop()}>
                   {t('navbar-4')}
               </SidebarLink>
               </SidebarMenu>
               <SideBtnWrap>
                   <SidebarRoute to='/signin'>{t('navbar-button')}</SidebarRoute>
               </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
